import { render, staticRenderFns } from "./TableFunctional.vue?vue&type=template&id=26468020&scoped=true&functional=true&"
import script from "./TableFunctional.vue?vue&type=script&lang=js&"
export * from "./TableFunctional.vue?vue&type=script&lang=js&"
import style0 from "./TableFunctional.vue?vue&type=style&index=0&id=26468020&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "26468020",
  null
  
)

export default component.exports