export const DefaultTableColumn = [
  { label: '新增用户', value: 'newUsers', type: 'text' },
  { label: '活跃用户', value: 'activeUser', type: 'text' },
  { label: '付费用户', value: 'payUser', type: 'text' },
  { label: '充值金额总计', value: 'payAmount', type: 'text' },
  { label: '新增设备', value: 'newDevices', type: 'text' },
  { label: '新增付费用户', value: 'newPayUser', type: 'text' },
  { label: '新增付费用户的充值金额总计', value: 'newPayAmount', type: 'text' },
  { label: '游戏启动数', value: 'userLaunchNum', type: 'text' },
  { label: 'ARPU', value: 'arpu', type: 'text' },
  { label: 'ARPPU', value: 'arppu', type: 'text' },
  { label: '付费率(%)', value: 'payRate', type: 'text' },
  { label: '新增ARPPU', value: 'newArppu', type: 'text' }
]

export const HashTableColumn = {
  1: [{ label: '日期', value: 'dt', type: 'text' }],
  2: [{ label: '注册渠道', value: 'registerChannel', type: 'slot' }],
  3: [
    { label: '日期', value: 'dt', type: 'text' },
    { label: '注册渠道', value: 'registerChannel', type: 'slot' }
  ]
}
