<template>
  <el-card class="cps-view">
    <el-form :inline="true">
      <el-form-item>
        <channel-cascade :multiple="true"></channel-cascade>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="filterForm.fromDate"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <ExportExcel :disabled="!tableData.length" :filename="excelFileName" :headers="tableColumn" :data="execlTableData"/>
      </el-form-item>
    </el-form>
    <el-tabs v-model="tabValue" @tab-click="fetchData">
      <el-tab-pane v-for="(item, index) in tabs" :label="item.label" :name="item.value" :key="index"></el-tab-pane>
      <TableComponents
        :maxHeight="maxTableHeight"
        :show-summary="true"
        :summary-method="onSummaryMethod"
        :tableData="tableData"
        :column="tableColumn"
      >
        <template slot="registerChannel" slot-scope="{row, column}">
          {{ row[column.value] | channel }}
        </template>
      </TableComponents>
    </el-tabs>
  </el-card>
</template>

<script>
import TableComponents from '@/components/Layout/Table'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import ExportExcel from '@/components/Excel/ExportExcel'
import api from '@/api/cpsDaily.js'
import { DefaultTableColumn, HashTableColumn } from './index.js'
export default {
  name: 'CpsView',
  components: {
    ChannelCascade,
    ExportExcel,
    TableComponents
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      tabValue: '1',
      tabs: [
        { label: '数据总览', value: '1' },
        { label: '渠道数据', value: '2' },
        { label: '原始数据', value: '3' }
      ],
      filterForm: {
        // reportType: 'daily',
        fromDate: [
          new Date((+new Date() - 3600 * 1000 * 24 * 7)),
          new Date()
        ]
      },
      execlTableData: [],
      tableData: [],
      summary: {}
    }
  },
  computed: {
    tableColumn () {
      return [
        ...HashTableColumn[this.tabValue],
        ...DefaultTableColumn
      ]
    },
    excelFileName () {
      const obj = this.tabs.find(item => (item.value === this.tabValue))
      return `${this.$route.name}-${obj.label}`
    },
    maxTableHeight () {
      return document.body.clientHeight * 0.72
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$nextTick()
      const { channelCascadeIds } = this.$store.getters
      let form = {
        ...this.filterForm,
        type: +this.tabValue,
        channels: channelCascadeIds.reduce((pre, cur) => {
          const num = [...cur].pop()
          const value = num ? `&channels=${num}` : []
          return [...pre, ...(value)]
        }, [])
      }

      if (Array.isArray(this.filterForm.fromDate)) {
        const [fromDate, toDate] = this.filterForm.fromDate
        form = {
          ...form,
          fromDate: this.$moment(new Date(fromDate)).format('YYYY-MM-DD'),
          toDate: this.$moment(new Date(toDate)).format('YYYY-MM-DD')
        }
      } else {
        delete form.fromDate
      }
      // console.log(form)
      if (this.tableData.length) this.tableData = []
      const loading = this.$loading({
        target: document.querySelector('.cps-view'),
        text: '拼命加载中,请稍等....'
      })
      api.getList(form).then((res) => {
        const { summary, rows } = res.data
        // console.log(summary, rows)

        this.summary = summary || {}
        this.tableData = rows || []

        this.execlTableData = [
          ...(rows || []),
          this.tableColumn.reduce((pre, cur, index) => {
            const obj = { ...pre }
            obj[cur.value] = index === 0 ? '合计' : (summary || {})[cur.value] === undefined ? '' : (summary || {})[cur.value]
            return obj
          }, {})
        ]
        // console.log(this.execlTableData)
      }).finally(() => loading.close())
    },
    onSummaryMethod ({ columns, data }) {
      // console.log(columns, data)
      return columns.map((item, index) => {
        // if (item.property === 'dt') return '合计'
        if (index === 0) return '合计'
        // console.log(this.summary[item.value])
        return this.summary[item.property] === undefined ? '' : this.summary[item.property]
      })
    }
  }
}
</script>

<style scoped>
</style>
