import { render, staticRenderFns } from "./Popover.vue?vue&type=template&id=6a916ef6&scoped=true&"
import script from "./Popover.vue?vue&type=script&lang=js&"
export * from "./Popover.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a916ef6",
  null
  
)

export default component.exports