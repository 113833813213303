
<template functional>
  <!-- functional无状态函数组件，降低渲染开销 -->
  <el-table :data="props.tableData" align="center" v-on="listeners" v-bind="data.attrs">
    <template v-for="(item, index) in props.column">
      <!-- TODO: 后续按需再增加 -->
      <el-table-column
        v-if="item.type === 'text'"
        align="center"
        :key="index" :prop="item.value" :label="item.label" v-bind="item.bind"
      >
      </el-table-column>

      <el-table-column
        v-if="['date', 'datetime'].includes(item.type)"
        align="center"
        :key="index" :prop="item.value" :label="item.label" v-bind="item.bind"
      >
        <template slot-scope="{row}">
          {{ $options.formatDate(item, row) }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="['boolean', 'hash'].includes(item.type)"
        align="center"
        :key="index" :prop="item.value" :label="item.label" v-bind="item.bind"
      >
        <template slot-scope="{ row }">
          <template v-if="item.type === 'boolean'">
            {{ row[item.value] ? item.options[1] : item.options[0] }}
          </template>
          <template v-if="item.type === 'hash'">
            {{ item.options[row[item.value]] }}
          </template>
        </template>
      </el-table-column>

      <el-table-column
        v-if="['image'].includes(item.type)"
        align="center"
        :key="index"
        :prop="item.value"
        :label="item.label"
        v-bind="item.bind"
      >
        <template slot-scope="{row}">
          <el-image
            :fit="'contain'"
            :style="item.imageStyle"
            :src="row[item.value]"
            :preview-src-list="[row[item.value]]"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column
        v-if="item.type === 'slot'"
        align="center"
        :key="index" :prop="item.value" :label="item.label" v-bind="item.bind"
      >
        <template slot-scope="{ row, $index }">
          <slot :name="item.value" :row="row" :column="item" :index="$index"></slot>
        </template>
      </el-table-column>

    </template>
  </el-table>
</template>
<script>
export default {
  formatDate (item, form) {
    const dateType = {
      date: 'toLocaleDateString',
      datetime: 'toLocaleString'
    }
    return form[item.value]
      ? new Date(form[item.value])[dateType[item.type]]('zh-cn').replaceAll('/', '-')
      : form[item.value]
  }
}
</script>
<style scoped>
</style>
