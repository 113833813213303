import http from './http'

export default {
  // 组织接口
  getOrganizes (params) {
    return http.get('/policy/admin/organizes', { params: params })
  },
  createOrganize (data) {
    return http.post('/policy/admin/organizes', data)
  },
  getOrganize (id) {
    return http.get(`/policy/admin/organizes/${id}`)
  },
  updateOrganize (data) {
    return http.put(`/policy/admin/organizes/${data.id}`, data)
  },
  deleteOrganize (id) {
    return http.delete(`/policy/admin/organizes/${id}`)
  },
  // 游戏组织接口
  getGameOrganizes (params) {
    return http.get('/policy/admin/game/organizes', { params: params })
  },
  createGameOrganize (data) {
    return http.post('/policy/admin/game/organizes', data)
  },
  getGameOrganize (id) {
    return http.get(`/policy/admin/game/organizes/${id}`)
  },
  updateGameOrganize (data) {
    return http.put(`/policy/admin/game/organizes/${data.id}`, data)
  },
  deleteGameOrganize (id) {
    return http.delete(`/policy/admin/game/organizes/${id}`)
  },
  // 渠道组织接口
  getChannelOrganizes (params) {
    return http.get('/policy/admin/channel/organizes', { params: params })
  },
  createChannelOrganize (data) {
    return http.post('/policy/admin/channel/organizes', data)
  },
  getChannelOrganize (id) {
    return http.get(`/policy/admin/channel/organizes/${id}`)
  },
  updateChannelOrganize (data) {
    return http.put(`/policy/admin/channel/organizes/${data.id}`, data)
  },
  deleteChannelOrganize (id) {
    return http.delete(`/policy/admin/channel/organizes/${id}`)
  }
}
