<template>
  <section>
    <div class="box">
      <el-tabs type="border-card" v-model="tabActive" @tab-click="handleTabClick">
        <el-tab-pane name="tab1" :label="tabs.tab1.label">
          <!-- 组织主体表单 -->
          <el-form :inline="true">
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="handleOrganizeAdd">新增</el-button>
            </el-form-item>
            <el-form-item>
              <el-input v-model="search.enName" placeholder="筛选：索引名" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="search.renderType" placeholder="筛选：渲染类型" filterable clearable>
                <el-option value="">全部</el-option>
                <el-option v-for="item in organizeRenderTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="search.status" placeholder="筛选：状态" filterable clearable>
                <el-option value="">全部</el-option>
                <el-option v-for="item in organizeStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="fetchOrganizes" :loading="loading" icon="el-icon-search">查询</el-button>
            </el-form-item>
          </el-form>
          <!-- 组织主体列表 -->
          <el-table :data="organizes" style="width: 100%" stripe>
            <el-table-column prop="id" label="ID" width="100px"></el-table-column>
            <el-table-column prop="enName" label="索引名">
              <template slot-scope="scope">
                <el-button type="text" @click="handleOrganizeEdit(scope.$index, scope.row)">{{scope.row.enName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="zhName" label="组织名称"></el-table-column>
            <el-table-column prop="privacyContent" label="隐私政策" width="120px">
              <template slot-scope="scope">
                  <el-button slot="reference" :type="getContentButtonType(scope.row.renderType)" size="mini" plain @click="handleContentShow('隐私政策', 'privacyContent', scope.row)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="protocolContent" label="用户协议" width="120px">
              <template slot-scope="scope">
                <el-button slot="reference" :type="getContentButtonType(scope.row.renderType)" size="mini" plain @click="handleContentShow('用户协议', 'protocolContent', scope.row)" v-if="scope.row.protocolContent">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="renderType" label="渲染类型" :formatter="renderTypeFormatter" width="120px"></el-table-column>
            <el-table-column prop="status" label="状态" :formatter="statusFormatter" width="120px"></el-table-column>
            <el-table-column prop="note" label="备注" width="120px">
              <template slot-scope="scope">
                <el-popover placement="right" trigger="hover" :content="scope.row.note" v-if="scope.row.note">
                  <el-button slot="reference" size="mini">查看</el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="创建时间" :formatter="dateFormatter"></el-table-column>
            <el-table-column prop="updatedAt" label="修改时间" :formatter="dateFormatter"></el-table-column>
            <el-table-column label="操作" fixed="right" align="right" width="80">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleOrganizeDelete(scope.row.id, scope.$index)" :disabled="scope.row.id <= 10"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="tab2" :label="tabs.tab2.label">
          <el-form :inline="true">
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="handleGameOrganizeAdd">新增</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="fetchGameOrganizes" :loading="loading" icon="el-icon-full-screen">查询</el-button>
            </el-form-item>
          </el-form>
          <!-- 游戏组织列表 -->
          <el-table :data="gameOrganizes">
            <el-table-column prop="id" label="游戏ID" :formatter="gameFormatter"></el-table-column>
            <el-table-column prop="organizeId" label="组织主体" :formatter="organizeFormatter"></el-table-column>
            <el-table-column prop="note" label="备注" width="120px">
              <template slot-scope="scope">
                <el-popover placement="right" trigger="hover" :content="scope.row.note" v-if="scope.row.note">
                  <el-button slot="reference" size="mini">查看</el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="创建时间" :formatter="dateFormatter"></el-table-column>
            <el-table-column prop="updatedAt" label="修改时间" :formatter="dateFormatter"></el-table-column>
            <el-table-column label="操作" fixed="right" align="right" width="80">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleGameOrganizeDelete(scope.row.id, scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="tab3" :label="tabs.tab3.label">
          <el-form :inline="true">
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="handleChannelOrganizeAdd">新增</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="fetchChannelOrganizes" :loading="loading" icon="el-icon-time">查询</el-button>
            </el-form-item>
          </el-form>
          <!-- 渠道组织列表 -->
          <el-table :data="channelOrganizes">
            <el-table-column prop="id" label="渠道ID" :formatter="channelFormatter"></el-table-column>
            <el-table-column prop="organizeId" label="组织主体" :formatter="organizeFormatter"></el-table-column>
            <el-table-column prop="note" label="备注" width="120px">
              <template slot-scope="scope">
                <el-popover placement="right" trigger="hover" :content="scope.row.note" v-if="scope.row.note">
                  <el-button slot="reference" size="mini">查看</el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="创建时间" :formatter="dateFormatter"></el-table-column>
            <el-table-column prop="updatedAt" label="修改时间" :formatter="dateFormatter"></el-table-column>
            <el-table-column label="操作" fixed="right" align="right" width="80">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleChannelOrganizeDelete(scope.row.id, scope.$index)" :disabled="Math.floor(scope.row.id/1000) !== appId"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <!-- 组织主体对话框 -->
      <el-dialog title="组织主体信息" :visible="dialogVisible" @close="handleDialogClose" top="4vh">
        <el-form :model="form" label-position="right" label-width="120px" :rules="rules" ref="form">
          <el-form-item label="ID" prop="id">
            <el-input v-model.number="form.id" placeholder="ID" clearable :disabled="formEdit"></el-input>
          </el-form-item>
          <el-form-item label="索引名" prop="enName">
            <el-input v-model.trim="form.enName" placeholder="索引名" clearable></el-input>
          </el-form-item>
          <el-form-item label="组织名称" prop="zhName">
            <el-input v-model.trim="form.zhName" placeholder="组织名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="渲染类型" prop="renderType">
            <el-select v-model.number="form.renderType" placeholder="请选择渲染类型" filterable clearable>
              <el-option v-for="item in organizeRenderTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model.number="form.status" placeholder="请选择状态" filterable clearable>
              <el-option v-for="item in organizeStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="隐私政策" prop="privacyContent">
            <el-input type="textarea" v-model="form.privacyContent" placeholder="隐私政策"></el-input>
          </el-form-item>
          <el-form-item label="用户协议" prop="privacyContent">
            <el-input type="textarea" v-model="form.protocolContent" placeholder="用户协议"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input type="textarea" v-model="form.note" placeholder="备注"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleDialogSave">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 隐私协议-游戏配置 -->
      <el-dialog title="隐私协议-游戏配置" :visible="goDialogVisible" @close="handleGoDialogClose" top="4vh">
        <el-form :model="goForm" label-position="right" label-width="120px" :rules="goRules" ref="goForm">
          <el-form-item label="游戏ID" prop="id">
            <el-select v-model.number="goForm.id" placeholder="请选择游戏" filterable clearable class="fm-block-with">
              <el-option v-for="item in gameOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组织ID" prop="organizeId">
            <el-select v-model.number="goForm.organizeId" placeholder="请选择组织" filterable clearable class="fm-block-with">
              <el-option v-for="item in organizeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input type="textarea" v-model="goForm.note" placeholder="备注"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleGoDialogSave">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 隐私协议-渠道配置 -->
      <el-dialog title="隐私协议-渠道配置" :visible="coDialogVisible" @close="handleCoDialogClose" top="4vh">
        <el-form :model="coForm" label-position="right" label-width="120px" :rules="coRules" ref="coForm">
          <el-form-item label="渠道ID" prop="id">
            <el-select v-model.number="coForm.id" placeholder="请选择游戏" filterable clearable class="fm-block-with">
              <el-option v-for="item in channelOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组织ID" prop="organizeId">
            <el-select v-model.number="coForm.organizeId" placeholder="请选择组织" filterable clearable class="fm-block-with">
              <el-option v-for="item in organizeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input type="textarea" v-model="coForm.note" placeholder="备注"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleCoDialogSave">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog :title="showTitle" :visible="showDialogVisible" @close="showDialogVisible=false" top="5vh">
        <el-alert :title="showAlertInfo" type="warning" :closable="false" show-icon></el-alert>
        <el-link v-if="showRenderType===9" :href="showContent" target="_blank">{{ showContent }}</el-link>
        <p v-else>{{ showContent }}</p>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import api from '../../api/policy'
import options from '../../common/option'
import f from '../../filter'
import util from '../../common/util'
export default {
  name: 'PolicyOrganize',
  data () {
    const checkEnName = (rule, value, callback) => {
      const patten = /^[a-zA-Z0-9-.]+$/
      if (!patten.test(value)) {
        return callback(new Error('只能包含英文字母、数字、减号、点号'))
      }
      callback()
    }
    return {
      organizes: [],
      loading: false,
      search: {
        enName: '',
        renderType: '',
        status: ''
      },
      dialogVisible: false,
      form: {},
      formEdit: false,
      editIndex: 0,
      rules: {
        id: [{ type: 'number', required: true, message: '请输入数字', trigger: 'blur' }],
        enName: [
          { required: true, message: '请输入索引名', trigger: 'blur' },
          { min: 0, max: 64, message: '支持的长度为1-64个字符', trigger: 'blur' },
          { validator: checkEnName, trigger: 'blur' }
        ],
        zhName: [{ type: 'string', required: true, message: '请输入组织名称', trigger: 'blur' }],
        renderType: [{ required: true, message: '请选择渲染类型', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      gameOrganizes: [],
      goLoading: false,
      goDialogVisible: false,
      goForm: {},
      goRules: {
        id: [{ type: 'number', required: true, message: '游戏ID必须是数字', trigger: 'change' }],
        organizeId: [{ type: 'number', required: true, message: '组织ID必须是数字', trigger: 'change' }]
      },
      channelOrganizes: [],
      coLoading: false,
      coDialogVisible: false,
      coForm: {},
      coRules: {
        id: [{ type: 'number', required: true, message: '渠道ID必须是数字', trigger: 'change' }],
        organizeId: [{ type: 'number', required: true, message: '组织ID必须是数字', trigger: 'change' }]
      },
      tabs: {
        tab1: { label: '组织主体', fetchFunc: this.fetchOrganizes, autoLoaded: false },
        tab2: { label: '游戏配置', fetchFunc: this.fetchGameOrganizes, autoLoaded: false },
        tab3: { label: '渠道配置', fetchFunc: this.fetchChannelOrganizes, autoLoaded: false }
      },
      tabActive: 'tab1',
      showTitle: '',
      showContent: '',
      showAlertInfo: '',
      showRenderType: 0,
      showDialogVisible: false
    }
  },
  computed: {
    organizeRenderTypeOptions () {
      return options.organizeRenderTypeOptions
    },
    organizeStatusOptions () {
      return options.organizeStatusOptions
    },
    gameOptions () {
      return this.$store.getters.games
    },
    channelOptions () {
      return this.$store.getters.channels
    },
    appId () {
      return this.$store.getters.appId
    },
    organizeOptions () {
      let options = []
      for (let o of this.organizes) {
        options.push({ value: o.id, label: o.id + '-' + o.zhName })
      }
      return options
    }
  },
  created () {
    this.autoFetchData()
  },
  methods: {
    handleTabClick () {
      this.autoFetchData()
    },
    autoFetchData () {
      const tab = this.tabs[this.tabActive]
      if (!tab || tab.autoLoaded) {
        return
      }
      const func = tab.fetchFunc
      if (!func) {
        return
      }
      func()
    },
    fetchOrganizes () {
      this.organizes = []
      this.loading = true
      const params = {}
      for (let key in this.search) {
        if (this.search[key] !== '') {
          params[key] = this.search[key]
        }
      }
      api.getOrganizes(params).then(res => {
        this.loading = false
        this.organizes = res
        this.tabs.tab1.autoLoaded = true
      }).catch(() => {
        this.loading = false
      })
    },
    handleDialogClose () {
      this.dialogVisible = false
      this.$refs.form.clearValidate()
    },
    handleOrganizeAdd () {
      this.formEdit = false
      let maxId = this.organizes.length ? this.organizes[this.organizes.length - 1].id : 0
      // 10以内的ID系统保留
      if (maxId <= 10) {
        maxId = 10
      }
      this.form = {
        id: maxId + 1,
        enName: '',
        zhName: '',
        renderType: 1,
        status: 1,
        privacyContent: '',
        protocolContent: '',
        note: ''
      }
      this.dialogVisible = true
    },
    handleOrganizeEdit (index, row) {
      this.formEdit = true
      this.editIndex = index
      this.form = Object.assign({}, row)
      this.dialogVisible = true
    },
    handleDialogSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const privacy = this.form.privacyContent
          const protocol = this.form.protocolContent
          if (!privacy && !protocol) {
            this.$message.error('隐私政策和协议不能同时为空')
            return
          }
          // URL渲染类型，需要检查URL是否合法
          if (this.form.renderType === 9) {
            if (privacy && !util.hasUrlPrefix(privacy)) {
              this.$message.error('隐私政策：URL必须以http://或https://开头')
              return
            }
            if (protocol && !util.hasUrlPrefix(protocol)) {
              this.$message.error('用户协议：URL必须以http://或https://开头')
              return
            }
          }
          const func = this.formEdit ? api.updateOrganize : api.createOrganize
          const data = Object.assign({}, this.form)
          func(data).then(res => {
            if (this.formEdit) {
              this.organizes.splice(this.editIndex, 1, res)
            } else {
              this.organizes.push(res)
            }
            this.$message.success(this.formEdit ? '修改成功' : '添加成功')
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },
    handleOrganizeDelete (id, i) {
      this.$confirm(`ID: ${id}, 确定删除吗?`, '提示', { type: 'warning' }).then(() => {
        api.deleteOrganize(id).then(() => {
          this.organizes.splice(i, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    handleContentShow (title, prop, row) {
      this.showTitle = title
      this.showContent = row[prop]
      this.showRenderType = row.renderType
      const renderLabel = f.optionsFormat(row.renderType, this.organizeRenderTypeOptions)
      this.showAlertInfo = `ID=${row.id}，${row.zhName}，${renderLabel}，以下是其【${title}】原始内容：`
      this.showDialogVisible = true
    },
    renderTypeFormatter (row, column, cellValue, index) {
      return f.optionsFormat(cellValue, this.organizeRenderTypeOptions)
    },
    statusFormatter (row, column, cellValue, index) {
      return f.optionsFormat(cellValue, this.organizeStatusOptions)
    },
    organizeFormatter (row, column, cellValue, index) {
      return f.optionsFormat(cellValue, this.organizeOptions)
    },
    dateFormatter (row, column, cellValue, index) {
      return cellValue ? this.$moment(cellValue).format('YYYY-MM-DD HH:mm') : ''
    },
    gameFormatter (row, column, cellValue, index) {
      return f.optionsFormat(cellValue, this.gameOptions)
    },
    channelFormatter (row, column, cellValue, index) {
      return f.optionsFormat(cellValue, this.channelOptions)
    },
    fetchGameOrganizes () {
      this.gameOrganizes = []
      this.goLoading = true
      api.getGameOrganizes().then(res => {
        this.goLoading = false
        this.gameOrganizes = res
        this.tabs.tab2.autoLoaded = true
      }).catch(() => {
        this.goLoading = false
      })
    },
    handleGameOrganizeAdd () {
      this.goForm = { id: this.appId, organizeId: '' }
      this.goDialogVisible = true
    },
    handleGoDialogClose () {
      this.goDialogVisible = false
      this.$refs.goForm.clearValidate()
    },
    handleGoDialogSave () {
      // 只处理新增即可
      this.$refs.goForm.validate((valid) => {
        if (valid) {
          const data = Object.assign({}, this.goForm)
          api.createGameOrganize(data).then(res => {
            this.gameOrganizes.push(res)
            this.$message.success('添加成功')
            this.goDialogVisible = false
          })
        } else {
          return false
        }
      })
    },
    handleGameOrganizeDelete (id, i) {
      this.$confirm(`游戏ID: ${id}, 确定删除吗?`, '提示', { type: 'warning' }).then(() => {
        api.deleteGameOrganize(id).then(() => {
          this.gameOrganizes.splice(i, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    fetchChannelOrganizes () {
      this.channelOrganizes = []
      this.coLoading = true
      api.getChannelOrganizes().then(res => {
        this.coLoading = false
        this.channelOrganizes = res
        this.tabs.tab3.autoLoaded = true
      }).catch(() => {
        this.coLoading = false
      })
    },
    handleChannelOrganizeAdd () {
      this.coForm = { id: '', organizeId: '' }
      this.coDialogVisible = true
    },
    handleCoDialogClose () {
      this.coDialogVisible = false
      this.$refs.coForm.clearValidate()
    },
    handleCoDialogSave () {
      // 只处理新增即可
      this.$refs.coForm.validate((valid) => {
        if (valid) {
          const data = Object.assign({}, this.coForm)
          api.createChannelOrganize(data).then(res => {
            this.channelOrganizes.push(res)
            this.$message.success('添加成功')
            this.coDialogVisible = false
          })
        } else {
          return false
        }
      })
    },
    handleChannelOrganizeDelete (id, i) {
      this.$confirm(`ID: ${id}, 确定删除吗?`, '提示', { type: 'warning' }).then(() => {
        api.deleteChannelOrganize(id).then(() => {
          this.channelOrganizes.splice(i, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    getContentButtonType (renderType) {
      if (renderType === 2) {
        return 'primary'
      }
      if (renderType === 9) {
        return 'info'
      }
      return 'default'
    }
  }
}
</script>

<style scoped>
.fm-block-with {
  width: 90%;
}
.el-form-item {
  margin-bottom: 20px;
}
</style>
