import http from './http'
import store from '@/store'

export default {
  getList (form) {
    const data = Object.entries(form).reduce((pre, [key, value]) => {
      const val = Array.isArray(value) ? value.join('') : `&${key}=${value}`
      return `${pre}${val}`
    }, '')
    return http.get(
      `/bi-cps/admin/report/daily/list?appId=${+store.getters.appId}${data}`
    )
  }
}
