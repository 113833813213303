<template>
  <h2 class="home-title">欢迎来到BI-CPS后台</h2>
</template>

<script>
export default {
  components: {},
  data () {
    return {
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  mounted () {},
  methods: {}
}
</script>
<style scoped>
.home-title {
  text-align: center;
}
</style>
