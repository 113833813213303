import http from './http'

export default {
  getFeeBill (params) {
    return http.get(`/api/fee/bill`, { params: params })
  },
  getSdkBill (params) {
    return http.get(`/api/sdk/bill`, { params: params })
  },
  getFeeBillDetail (params) {
    return http.get(`/api/fee/bill-detail`, { params: params })
  },
  getSdkBillDetail (params) {
    return http.get(`/api/sdk/bill-detail`, { params: params })
  },
  getOrders3 (params) {
    return http.get(`/api/fee/orders3`, { params: params })
  },
  getOrders (params) {
    return http.get(`/api/fee/orders`, { params: params })
  },
  getIosOrders (params) {
    return http.get(`/api/sdk/orders`, { params: params })
  },
  orderRenotify (orderId) {
    return http.get(`/api/ufo/order-renotify`, { params: { orderId: orderId } })
  }
}
