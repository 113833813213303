/**
 * 请求接口方法
 * @author Mr.姜
 * @param http  封装的axios请求方式
 * @since 2018/10/12
 * */

import http from '@/api/http'
import store from '@/store'

const doHttpRequest = (url, isServer, params) => {
  let appId = store.getters.appId
  let channelCascadeIds = store.getters.channelCascadeIds
  let channels = []
  let serverList = store.getters.serverList
  for (let c of channelCascadeIds) {
    if (c.length > 1) {
      channels.push(c[1])
    }
  }
  let reqParams = { st: store.getters.dataPickerValue[0], et: store.getters.dataPickerValue[1], channels: channels }
  if (isServer) {
    reqParams.servers = serverList
  }
  if (params) {
    reqParams = Object.assign({}, reqParams, params)
  }
  return !isServer ? http.get(`/bi/${appId}${url}`, { params: reqParams }) : http.get(`/bi/${appId}/server${url}`, { params: reqParams })
  // return http.get(`/bi/${appId}${uri}`, { params: reqParams })
}

export default {
// 获取指标列表
//   getTargetList (appId) {
//     return http.get(`/bi/${appId}/custom/target`)
//   },
//   // 获取自定义概况已有图表列表
//   getCharsInfoList (appId) {
//     return http.get(`/bi/${appId}/custom/chart`)
//   },
//   // 删除自定义概况已有图表
//   deleteCharsInfo (appId, id) {
//     return http.delete(`/bi/${appId}/custom/chart/${id}`)
//   },
//   // 添加自定义概况图表
//   addCharsInfo (appId, params) {
//     return http.post(`/bi/${appId}/custom/chart`, params)
//   },
  async getMultiTargetData (targets, targetApiFunc, isServer) {
    let defaultApiFunc = targetApiFunc || this.getTargetData
    let apiResPromises = []
    for (let t of targets) {
      let apiFunc = t.apiFunc || defaultApiFunc
      // 先将promise请求同时发送
      let res = apiFunc(t.value, isServer)
      apiResPromises.push({ target: t.value, promise: res })
    }
    let data = {}
    for (let d of apiResPromises) {
      // 等待结果
      let res = await d.promise
      // data[d.target] = res
      data[d.target] = res
    }
    return data
  },
  // 获取游戏总览
  getDashboard (isServer) {
    return doHttpRequest(`/dashboard`, isServer)
  },
  // 获取矩阵指标
  getMatrixTargetData (isServer, matrix, params) {
    return doHttpRequest(`/matrix/${matrix}`, isServer, params)
  },
  getWeeklyMatrixTargetData (matrix, params) {
    return doHttpRequest(`/matrix/weekly/${matrix}`, params)
  },
  getMonthlyMatrixTargetData (isServer, matrix, params) {
    return doHttpRequest(`/matrix/monthly/${matrix}`, isServer, params)
  },
  // 获取各个指标对应的数据
  getTargetData (target, isServer) {
    return doHttpRequest(`/target/${target}`, isServer)
  },
  getTargetWeekly (target) {
    return doHttpRequest(`/target/weekly/${target}`)
  },
  getTargetMonthly (target) {
    return doHttpRequest(`/target/monthly/${target}`)
  },
  // 指标：渠道分布
  getTargetChannel (target, isServer) {
    return doHttpRequest(`/target/channel/${target}`, isServer)
  },
  // 指标：等级分布
  getTargetLevel (target, isServer) {
    return doHttpRequest(`/target/level/${target}`, isServer)
  },
  // 指标：服务器分布
  getTargetServer (target, isServer) {
    return doHttpRequest(`/target/server/${target}`, isServer)
  },
  // 指标：设备机型
  getTargetModel (target, isServer) {
    return doHttpRequest(`/target/model/${target}`, isServer)
  },
  // 指标：设备分辨率
  getTargetResolution (target, isServer) {
    return doHttpRequest(`/target/resolution/${target}`, isServer)
  },
  // 指标：系统
  getTargetOS (target, isServer) {
    return doHttpRequest(`/target/os/${target}`, isServer)
  },
  // 首充相关
  getFirstPayLevel (isServer) {
    return doHttpRequest(`/target/firstPayLevel/payUser`, isServer)
  },
  getFirstPayProduct (isServer) {
    return doHttpRequest(`/target/firstPayProduct/payUser`, isServer)
  },
  getFirstPayDays (isServer) {
    return doHttpRequest(`/target/firstPayInterval/payUser`, isServer)
  },
  getFirstPayMoney (isServer) {
    return doHttpRequest(`/target/firstPayMoney/payUser`, isServer)
  },
  getTargetLoginUserAddDevice (target) {
    return doHttpRequest(`/target/loginUserAddRemoval`)
  },
  // 地理位置
  getRegion (target) {
    return doHttpRequest(`/target/region/${target}`)
  },
  // 付费相关

  // 付费留存
  // 日期分布
  getPayKeepDate (params) {
    return doHttpRequest(`/pay/keep/date`, null, params)
  },
  // 区间分布
  getPayKeepRange (params) {
    return doHttpRequest(`/pay/keep/range`, null, params)
  },
  // 指标分布
  getPayKeepTarget (params) {
    return doHttpRequest(`/pay/keep/target`, null, params)
  },

  // 滚服分布
  // 新增用户
  getRollLoginUserAdd (serverId) {
    return doHttpRequest(`/roll/${serverId}/loginUserAdd`)
  },
  // 活跃用户
  getRollLoginUser (serverId) {
    return doHttpRequest(`/roll/${serverId}/loginUser`)
  },
  // 付费金额
  getRollPayMoney (serverId) {
    return doHttpRequest(`/roll/${serverId}/payMoney`)
  },
  // 付费用户
  getRollPayUser (serverId) {
    return doHttpRequest(`/roll/${serverId}/payUser`)
  },

  // 充值分布
  getPayDistribution (type, isServer) {
    return doHttpRequest(`/target/${type}/payUser`, isServer)
  },
  // 留存相关

  // 留存分析
  getKeepAnalysis (analysisType, target, isServer) {
    return doHttpRequest(`/keep/${target}/${analysisType}`, isServer)
  },
  // 数据日报
  getReportDaily () {
    return doHttpRequest(`/report`)
  },
  getReportSumDaily () {
    return doHttpRequest(`/report/daily/get/total/data`)
  },
  getReportDailyChannel () {
    return doHttpRequest(`/report/channel`)
  },
  getReportDailyOrigin () {
    return doHttpRequest(`/report/origin`)
  },
  getLazyDailyData (params) {
    return doHttpRequest(`/report/get/total/target`, null, params)
  },
  // 分服日报
  getReportServer (params) {
    return doHttpRequest(`/server/report`, null, params)
  },
  getReportServerChannel (params) {
    return doHttpRequest(`/server/report/channel`, null, params)
  },
  getReportServerDay (params) {
    return doHttpRequest(`/server/report/serverday`, null, params)
  },
  getReportServerSer (params) {
    return doHttpRequest(`/server/report/server`, null, params)
  },
  getReportServerOrigin (params) {
    return doHttpRequest(`/server/report/origin`, null, params)
  },
  // 分服月报
  getReportMonthlyServerDate (params) {
    return doHttpRequest(`/server/report/monthly/date`, null, params)
  },
  getReportMonthlyServerChannel (params) {
    return doHttpRequest(`/server/report/monthly/channel`, null, params)
  },
  getReportMonthlyServer (params) {
    return doHttpRequest(`/server/report/monthly/server`, null, params)
  },
  getReportMonthlyServerOrigin (params) {
    return doHttpRequest(`/server/report/monthly/origin`, null, params)
  },
  // 数据月报
  getReportMonthly (params) {
    return doHttpRequest(`/report/monthly/date`, null, params)
  },
  getReportSumMonthly (params) {
    return doHttpRequest(`/report/monthly/get/total/data`, null, params)
  },
  getReportMonthlyChannel (params) {
    return doHttpRequest(`/report/monthly/channel`, null, params)
  },
  getReportMonthlyOrigin (params) {
    return doHttpRequest(`/report/monthly/origin`, null, params)
  },
  // 流程统计
  getProcess (type) {
    return doHttpRequest(`/${type}/process`)
  },
  // 鲸鱼用户
  getWhaleData (params) {
    return doHttpRequest(`/search/whale`, null, params)
  },
  // 用户查询
  getUserData (params) {
    return doHttpRequest(`/search/user`, null, params)
  },
  // 角色查询
  getRoleData (params) {
    return doHttpRequest(`/search/role`, null, params)
  },
  // 角色查询
  getUserRoleList (userId) {
    return doHttpRequest(`/search/user/${userId}/role`)
  },
  // 数据周报
  getReportWeekly () {
    return doHttpRequest(`/report/weekly/date`)
  },
  getReportWeeklyChannel () {
    return doHttpRequest(`/report/weekly/channel`)
  },
  getReportWeeklyOrigin () {
    return doHttpRequest(`/report/weekly/origin`)
  },
  // IP限制
  getTOPIP (appId, params) {
    return http.get(`/bi/${appId}/ip-search`, { params: params })
  },
  getBlacklist () {
    return http.get(`/bi/gfw/ip-restrict?mode=blacklist`)
  },
  postBlacklist (data) {
    return http.post(`/bi/gfw/ip-restrict?mode=blacklist`, data)
  },
  deleteBlacklist (data) {
    return http.delete(`/bi/gfw/ip-restrict?mode=blacklist`, { data: data })
  },
  // 分服画像
  // 获取指标/阶段
  getPortraitParams () {
    return http.get(`/bi/${store.getters.appId}/server/portrait/params`)
  },
  // 数据对比
  getPortraitContrast (params) {
    return http.get(`/bi/${store.getters.appId}/server/portrait/contrast`, { params: params })
  },
  // 阶段数据
  getPortraitSnapshot (params) {
    return http.get(`/bi/${store.getters.appId}/server/portrait/snapshot`, { params: params })
  },
  // 获取账户迁移列表
  getAccountTransfer (appId) {
    return http.get(`/bi/${appId}/ext/user/transfer`)
  },
  // 账户迁移
  accountTransfer (appId, params) {
    return http.post(`/bi/${appId}/ext/user/transfer`, params)
  },

  // 广告数据
  // 数据报表
  getTgReport (param) {
    return http.get(`/bi/${store.getters.appId}/tg/daily`, { params: param })
  },
  // 子渠道列表
  getTgParams () {
    return http.get(`/bi/${store.getters.appId}/tg/params`)
  },
  // 对账
  getChannelFeeBills (params) {
    return http.get(`/bi/fee/bills`, { params: params })
  },

  // 技术补单
  patchOrder (params) {
    return http.patch('/bi/admin/ufo/order/reissue', params)
  }
}
